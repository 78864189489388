















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import { apiAfterSaleClaims, apiOrderGoodsInfo } from "@/api/order/order";
import { number } from "echarts/core";
import { timeFormat } from "@/utils/util";

@Component({
    components: { MaterialSelect },
})
export default class OrderLogistics extends Vue {
    @Prop({ default: "15vh" }) top!: string | number; //弹窗的距离顶部位置
    @Prop({ default: "0" }) id!: string | number; // ID
    /** S Data **/
    visible = false; //是否
    $refs!: { form: any };
    fullscreenLoading = false; //加载方式
    // 发货表单
    form: any = {
        compensate_type: "",
        loss_for_bearer: "",
        loss_for_amount: "",
        remark: "",
    };
    nDate = timeFormat(new Date().getTime(), "yyyy-mm-dd hh:MM:ss");

    /** S Method **/

    // 点击取消
    handleEvent(type: "cancel" | "confirm") {
        if (type === "cancel") {
            this.close();
        }
        if (type === "confirm") {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    // return;
                    this.fullscreenLoading = true;
                    apiAfterSaleClaims({ id: this.id, ...this.form })
                        .then((res) => {
                            this.fullscreenLoading = false;
                            this.$emit('refresh')
                            this.close();
                        })
                        .catch((err) => {
                            this.fullscreenLoading = false;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }

    // 打开弹窗
    async onTrigger() {
        this.visible = true;
    }

    // 关闭弹窗
    close() {
        this.form = {
            compensate_type: "",
            loss_for_bearer: "",
            loss_for_amount: "",
            remark: "",
        };
        this.visible = false;
    }

    //判断多选框是否可选
    selectableDisable(item: any) {
        return item.delivery_goods_sum < item.goods_num;
    }
}
