








































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import AreaSelect from "@/components/area-select.vue";
import OrderLogistics from "@/components/order/order-logistics.vue";
import ReOrderLogistics from "@/components/order/re-order-logistics.vue";
import compensationForm from "./compensationForm.vue";
import {
    apiAfterSaleDetail,
    apiAfterSaleAgree,
    apiAfterSaleRefuse,
    apiAfterSaleConfirmGoods,
    apiAfterSaleRefuseGoods,
    apiAfterSaleAgreeRefund,
    apiAfterSaleRefuseRefund,
    apiAfterSaleConfirmRefund,
    apiAfterSaleClaims,
} from "@/api/order/order";
@Component({
    components: {
        LsDialog,
        AreaSelect,
        OrderLogistics,
        ReOrderLogistics,
        compensationForm,
    },
})
export default class OrderDetail extends Vue {
    // S Data
    admin_remark: string = "";
    // 订单详情ID
    id: any = 0;
    // 订单数据
    orderData: any = {
        after_sale: {
            id: 2,
            order_id: 1,
            user_id: 1,
            sn: "",
            refund_type: 2,
            refund_type_desc: "",
            refund_method: 1,
            refund_method_desc: "",
            status: 3,
            status_desc: "",
            refund_reason: "",
            refund_remark: "",
            refund_image: "",
            create_time: "",
            express_name: "",
            invoice_no: "",
            express_remark: "",
            express_image: "",
        },
        return_goods_info: {
            user_sn: "",
            user_nickname: "",
            user_mobile: "",
        },
        order_info: {
            order_status: 3,
            order_status_desc: "",
            sn: "——",
            order_type: 0,
            order_type_desc: "",
            order_terminal: 1,
            order_terminal_desc: "",
            create_time: "",
            pay_status: 1,
            pay_status_desc: "",
            pay_way: 1,
            pay_way_desc: "",
            pay_time: "——",
            confirm_take_time: "——",
        },
        goods_info: {
            order_goods: [
                {
                    item_image: "",
                    spec_value_str: "——",
                    goods_name: "",
                    goods_image: "",
                    goods_price: "",
                    goods_num: 1,
                    total_price: null,
                    discount_price: null,
                    total_pay_price: "",
                    refund_amount: "",
                },
            ],
            order_goods_sum: {
                sum_refund_amount: 50,
                sum_total_pay_price: 80,
                sum_discount_price: 0,
                sum_total_price: 0,
                sum_goods_num: 1,
            },
        },
        after_sale_log: [
            {
                operator_role: 3,
                operator_id: 1,
                content: "",
                create_time: "",
                operator_name: "",
            },
        ],
        btns: {
            agree_btn: false,
            refuse_btn: false,
            refuse_goods_btn: false,
            confirm_goods_btn: false,
            agree_refund_btn: false,
            refuse_refund: false,
            change_btn: false,
        },
    };

    // 拒绝原因
    reason: String = "";

    // 退款的金额
    refund_total_amount: String = "";

    // 退款方式
    refund_way: String = "1";

    // E Data

    // S Methods

    toUserDetail() {
        this.$router.push({
            path: "/user/user_details",
            query: {
                id: this.orderData.after_sale.user_id,
            },
        });
    }

    // 打开售后凭证图片
    reviews(item: string) {
        window.open(item);
    }

    // 获取订单详情
    getOrderDetail() {
        apiAfterSaleDetail({ id: this.id }).then((res) => {
            this.orderData = res;
            this.refund_total_amount =
                res.goods_info.order_goods_sum.sum_refund_amount;
        });
    }

    afterSaleClaims() {}

    // 同意售后
    afterSaleAgree() {
        apiAfterSaleAgree({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 拒绝售后
    afterSaleRefuse() {
        apiAfterSaleRefuse({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 确认收货
    afterSaleConfirmGoods() {
        apiAfterSaleConfirmGoods({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 拒绝收货
    afterSaleRefuseGoods() {
        apiAfterSaleRefuseGoods({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 同意退款
    afterSaleAgreeRefund() {
        apiAfterSaleAgreeRefund({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 拒绝退款
    afterSaleRefuseRefund() {
        apiAfterSaleRefuseRefund({ id: this.id }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 确认退款
    afterSaleConfirmRefund() {
        apiAfterSaleConfirmRefund({
            id: this.id,
            refund_way: this.refund_way,
            refund_total_amount: this.refund_total_amount,
        }).then((res) => {
            this.getOrderDetail();
        });
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: "/order/order_detail",
            query: { id },
        });
    }

    // 商品信息的底部结算信息
    getSummaries(param: any) {
        const { columns, data } = param;
        const sums: any = [];
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                sums[0] = "总价";
                return;
            }
            const values = data.map((item: any) =>
                Number(item[column.property])
            );
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 1) {
                    return;
                }
                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    }
                    return prev;
                }, 0);
                if (index !== 2) {
                    sums[index] = "¥" + sums[index];
                }
            }
        });

        return sums;
    }

    // E Methods

    created() {
        this.id = this.$route.query.id;
        this.id && this.getOrderDetail();
    }
}
