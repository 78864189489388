































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
    apiOrderDeliveryInfo,
    apiOrderLogistics,
    apiOrderDelivery,
} from "@/api/order/order";
@Component
export default class OrderLogistics extends Vue {
    @Prop({ default: "5vh" }) top!: string | number; //弹窗的距离顶部位置
    @Prop({ default: "0" }) id!: string | number; //订单ID
    @Prop({ default: true }) flag!: Boolean; //是发货还是物流查询 true为发货 ，false为物流查询
    @Prop({ default: "" }) isShow!: string;
    @Prop() orderType: number | undefined;
    /** S Data **/
    visible = false; //是否

    fullscreenLoading = false; //加载方式

    // 物流订单信息
    orderData: any = {
        traces: {},
    };

    // delivery
    deliveryList = [];
    activeName = "0";

    // 发货表单
    form: any = {
        send_type: 1, //	是	int	配送方式:1-快递配送;2-无需快递
        express_id: "", //	是(配送方式为1时必选)	int	订单id
        invoice_no: "", //	是(配送方式为1时必选)	int	订单id
        remark: "", //	否	varchar	发货备注
        delivery_content: "", //发货内容
    };

    /** E Data **/

    /** S Method **/

    // 获取订单信息 flag 为 true的时候执行
    getOrderDeliveryInfo() {
        apiOrderDeliveryInfo({ id: this.id }).then((res) => {
            this.orderData = res;
            this.form.delivery_content = res.delivery_content;
            this.fullscreenLoading = false;
        });
    }

    // 获取物流查询
    getOrderLogistics() {
        apiOrderLogistics({ id: this.id }).then((res) => {
            this.deliveryList = res;
            this.fullscreenLoading = false;
        });
    }

    // 发货
    orderDelivery() {
        let checkGoodList: any = this.$refs.orderGoodsTable;
        checkGoodList = checkGoodList?.selection || [];
        console.log(checkGoodList, "sssss");
        let shopGoods: any = [];
        for (let i = 0; i < checkGoodList.length; i++) {
            shopGoods.push({
                id: checkGoodList[i]["id"],
                dropshipping: checkGoodList[i]["dropshipping"],
            });
        }
        this.form.shopGoods = shopGoods;
        if (this.orderData.order_type == 4) {
            this.form = {
                delivery_content: this.form.delivery_content,
            };
        }
        apiOrderDelivery({
            id: this.id,
            ...this.form,
        }).then((res) => {
            this.$emit("update", "");

            // this.getOrderLogistics()
        });
    }

    // 商品信息的底部结算信息
    getSummaries(param: any) {
        const { columns, data } = param;
        const sums: any = [];
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                return;
            }
            if (index == 9) {
                return "";
            }
            if (index === 1) {
                sums[2] = "总价";
                return;
            }
            const values = data.map((item: any) =>
                Number(item[column.property])
            );
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 4) {
                    return;
                }

                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    }
                    return prev;
                }, 0);

                if (index !== 5 && index !== 3) {
                    if (index == 8) {
                        sums[index] = sums[index].toFixed(0);
                    } else {
                        sums[index] = "¥" + sums[index].toFixed(2);
                    }
                }
            }
        });

        return sums;
    }

    // 点击取消
    handleEvent(type: "cancel" | "confirm") {
        if (type === "cancel") {
            this.close();
        }
        if (type === "confirm") {
            let checkGoodList: any = this.$refs.orderGoodsTable;
            checkGoodList = checkGoodList?.selection || [];
            console.log(checkGoodList, "sssss");

            let checkGoodSum = checkGoodList.length;
            if (!checkGoodSum) {
                return this.$message.error("请选择要发货的商品");
            }
            for (let i = 0; i < checkGoodList.length; i++) {
                if (checkGoodList[i]["dropshipping"] < 1) {
                    return this.$message.error("请填写正确的发货数量");
                }
                let remSum =
                    Number(checkGoodList[i]["goods_snap"]["goods_num"]) -
                    Number(checkGoodList[i]["delivery_goods_sum"]);
                // if()
                if (remSum < checkGoodList[i]["dropshipping"]) {
                    return this.$message.error("剩余待发货数量不足");
                }
            }

            if (this.flag) {
                // 虚拟发货
                if (this.orderData.order_type == 4) {
                    if (this.form.delivery_content.trim() == "") {
                        return this.$message.error("请输入发货内容");
                    }
                } else {
                    if (this.form.send_type == 1) {
                        if (this.form.express_id == "") {
                            return this.$message.error("请选择快递公司");
                        }
                        if (this.form.invoice_no == "") {
                            return this.$message.error("请填写快递单号");
                        }
                    }
                }
            }

            this.orderDelivery();
            this.close();
        }
    }

    // 打开弹窗
    onTrigger() {
        this.fullscreenLoading = true;
        if (this.flag) {
            this.getOrderDeliveryInfo();
        } else {
            this.getOrderDeliveryInfo();
            this.getOrderLogistics();
        }
        this.visible = true;
    }

    // 关闭弹窗
    close() {
        this.visible = false;
    }

    handleChange(val: string) {
        this.form.delivery_content = val.trim();
    }
    // @Watch('isShow')
    // changeShow(value: any) {
    //
    // }

    //判断多选框是否可选
    selectableDisable(item: any) {
        return item.delivery_goods_sum < item.goods_num;
    }
}
