var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ls-dialog__trigger",on:{"click":_vm.onTrigger}},[_vm._t("trigger")],2),_c('el-dialog',{attrs:{"coustom-class":"ls-dialog__content","title":"售后申请","visible":_vm.visible,"width":"800px","top":_vm.top,"modal-append-to-body":false,"center":"","before-close":_vm.close,"close-on-click-modal":false}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fullscreenLoading),expression:"fullscreenLoading"}]},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px","size":"small"}},[_c('el-form-item',{staticStyle:{"margin-bottom":"22px !important"},attrs:{"label":"赔付时间"}},[_vm._v(" "+_vm._s(_vm.nDate)+" ")]),_c('el-form-item',{staticStyle:{"margin-bottom":"22px !important"},attrs:{"label":"赔付类型","prop":"compensate_type","rules":{
                        required: true,
                        message: '请选择赔付类型',
                        trigger: 'change',
                    }}},[_c('el-select',{attrs:{"placeholder":"请选择售后类型"},model:{value:(_vm.form.compensate_type),callback:function ($$v) {_vm.$set(_vm.form, "compensate_type", $$v)},expression:"form.compensate_type"}},[_c('el-option',{attrs:{"label":"线下转账","value":"1"}}),_c('el-option',{attrs:{"label":"积分补偿","value":"2"}}),_c('el-option',{attrs:{"label":"卡券补偿","value":"3"}})],1)],1),_c('el-form-item',{staticStyle:{"margin-bottom":"22px !important"},attrs:{"label":"损失承担方","prop":"loss_for_bearer","rules":{
                        required: true,
                        message: '请选择损失承担方',
                        trigger: 'change',
                    }}},[_c('el-select',{attrs:{"placeholder":"请选择售后类型"},model:{value:(_vm.form.loss_for_bearer),callback:function ($$v) {_vm.$set(_vm.form, "loss_for_bearer", $$v)},expression:"form.loss_for_bearer"}},[_c('el-option',{attrs:{"label":"平台","value":"1"}}),_c('el-option',{attrs:{"label":"供应商","value":"2"}}),_c('el-option',{attrs:{"label":"平台&供应商","value":"3"}})],1)],1),_c('el-form-item',{staticStyle:{"margin-bottom":"22px !important"},attrs:{"label":"赔付金额","prop":"loss_for_amount","rules":{
                        required: true,
                        message: '请输入赔付金额',
                        trigger: 'blur',
                    }}},[_c('el-input',{model:{value:(_vm.form.loss_for_amount),callback:function ($$v) {_vm.$set(_vm.form, "loss_for_amount", $$v)},expression:"form.loss_for_amount"}})],1),_c('el-form-item',{staticStyle:{"margin-bottom":"22px !important"},attrs:{"label":"赔付说明"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleEvent('cancel')}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.handleEvent('confirm')}}},[_vm._v("确 认")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }