import request from "@/plugins/axios";

// S 订单

// 订单列表其他列表参数
export const apiOtherLists = () => request.get("/order.order/otherLists");

// 订单详情
export const apiOrderDetail = (params: any) =>
    request.get("/order.order/detail", { params });

// 券明细
export const apiOrderCardUseLog = (params: any) =>
    request.get("/order.order/getOrderCardUseLog", { params });

// 子订单详情
export const apiGetSubOrderInfo = (params: any) =>
    request.get("/order.order/getSubOrderInfo", { params });

// 订单列表
export const apiOrderLists = (params: any) =>
    request.get("/order.order/lists", { params });

// 集采订单列表
export const apigroupBuyingLists = (params: any) =>
    request.get("/order.order/groupBuyingLists", { params });
//
// 订单流水列表
export const apiorderFlowChartLists = (params: any) =>
    request.get("/order.order/orderFlowChartLists", { params });

// 商家备注
export const apiOrderRemarks = (params: any) =>
    request.post("/order.order/orderRemarks", params);

// 取消订单
export const apiOrderCancel = (params: any) =>
    request.post("/order.order/cancel", params);

// 发货信息
export const apiOrderDeliveryInfo = (params: any) =>
    request.get("/order.order/deliveryInfo", { params });

// 发货
export const apiOrderDelivery = (params: any) =>
    request.post("/order.order/delivery", params);

// 物流查询
export const apiOrderLogistics = (params: any) =>
    request.get("/order.order/logistics", { params });

// 编辑收货地址
export const apiOrderAddressEdit = (params: any) =>
    request.post("/order.order/addressEdit", params);

// 确认收货
export const apiOrderConfirm = (params: any) =>
    request.post("/order.order/confirm", params);

// 修改运费
export const apiOrderChangeExpressPrice = (params: any) =>
    request.post("/order.order/changeExpressPrice", params);

// 修改商品价格
export const apiOrderChangeGoodsPrice = (params: any) =>
    request.post("/order.order/changePrice", params);
// E 订单

// S 售后

//理赔
export const apiAfterSaleClaims = (data: any) =>
    request.post("/after_sale.after_sale/claims", data);

//重新发货
export const apiAfterSaleRecreateOrderInfo = (params: any) =>
    request.get("/after_sale.after_sale/recreateOrderInfo", { params });

//重新发货
export const apiAfterSaleLogistics = (data: any) =>
    request.post("/after_sale.after_sale/logistics", data);

// 申请售后商品信息
export const apiOrderGoodsInfo = (data: any) =>
    request.post("/after_sale.after_sale/orderGoodsInfo", data);
//申请售后
export const apiAfterSaleApply = (data: any) =>
    request.post("/after_sale.after_sale/apply", data);

// 撤销售后
export const apiAfterSaleCancel = (data: any) =>
    request.post("/after_sale.after_sale/cancel", data);

// 售后列表
export const apiAfterSaleLists = (params: any) =>
    request.get("/after_sale.after_sale/lists", { params });

// 售后详情
export const apiAfterSaleDetail = (params: any) =>
    request.get("/after_sale.after_sale/detail", { params });

// 同意售后
export const apiAfterSaleAgree = (params: any) =>
    request.post("/after_sale.after_sale/agree", params);

// 拒绝售后
export const apiAfterSaleRefuse = (params: any) =>
    request.post("/after_sale.after_sale/refuse", params);

// 卖家确认收货
export const apiAfterSaleConfirmGoods = (params: any) =>
    request.post("/after_sale.after_sale/confirmGoods", params);

// 卖家拒绝收货
export const apiAfterSaleRefuseGoods = (params: any) =>
    request.post("/after_sale.after_sale/refuseGoods", params);

// 卖家同意退款
export const apiAfterSaleAgreeRefund = (params: any) =>
    request.post("/after_sale.after_sale/agreeRefund", params);

// 卖家拒绝退款
export const apiAfterSaleRefuseRefund = (params: any) =>
    request.post("/after_sale.after_sale/refuseRefund", params);

// 卖家确认退款
export const apiAfterSaleConfirmRefund = (params: any) =>
    request.post("/after_sale.after_sale/confirmRefund", params);

//运营平台核销订单
export const apiPlatformWriteOrder = (params: any) =>
    request.get("/order.order/writeOrderList", { params });

//运营平台核销订单
export const apigetWriteOffInfo = (params: any) =>
    request.get("/order.order/getWriteOffInfo", { params });

export const apiPlatformZtOrder = (params: any) =>
    request.get("/order.order/ztOrderList", { params });
// E 售后

export const apiSettlementSubpageLists = (params: any) =>
    request.get('/order.settlement/subpage', { params })

export const apiSettlementLists = (params: any) =>
    request.get("/order.settlement/lists", { params });
export const apiCanBeSettlementLists = (params: any) =>
    request.get("/order.settlement/canBeSettlementLists", { params });
export const apiCreateSettlement = (params: any) =>
    request.post("/order.settlement/create", params);

// 赔付
export const apiCompenstateLists = (params: any) =>
    request.post("/after_sale.compensate/lists", params);
